<template>
  <div class="auth-login-wrapper">
    <div class="auth-form-heading">
      <p class="auth-form-title d-none d-lg-block">Repor Password</p>
    </div>
    <div class="auth-form-wrapper">
      <FormulateForm name="reset-password" action="" class="auth-form reset-password-form" v-model="values" @submit="resetPasswordHandler">
        <div class="form-field">
          <FormulateInput type="email" name="email" validation="required" v-model="email" placeholder="Insira o seu e-mail" />
          <div class="form-field-icon">
            <emailIcon/>
          </div>
        </div>
        <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
          <p>{{ formResponseMessage }}</p>
        </div>
        <button type="submit" class="generic-btn red-gradient auth-form-submit" @submit="resetPasswordHandler">Repor Password</button>
      </FormulateForm>
    </div>
    <button type="button" class="reset-password-link" @click="handleLogin">Iniciar Sessão</button>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo.vue";
import emailIcon from "@/components/svg/mail.vue";
import lockIcon from "@/components/svg/lock.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";

import {EventBus} from "@/event-bus";
import axios from "axios";
import qs from "qs";

export default {
    name: "auth",
    data() {
      return {
        values: {},
        errors: [],
        submitted: false,
        email: '',
        showMessage: false,
        formResponseMessage: '',
      };
    },
    components : {
      LogoBoxSVG,
      emailIcon,
      lockIcon,
      chevronLeft
    },
    methods: {
      handleLogin: function() {
        EventBus.$emit("bus-handleLogin");
      },
      clearResetPasswordHandler() {
        this.$formulate.reset('reset-password-form');
        this.email = '';
        this.showMessage = false;
        this.formResponseMessage = '';
      },
      resetPasswordHandler() {
        const requestBody = {
          email: this.email,
        };

        axios
            .post(process.env.VUE_APP_API_URL + 'customer/recover', qs.stringify(requestBody))
            .then((response) => {
              if (response.data.success) {
                this.clearResetPasswordHandler();
                this.handleLogin();
              }
            })
            .catch((e) => {
              this.errors.push(e);
              this.showMessage = true;
              this.formResponseMessage = 'An error occurred.';
            });
      },
    }
}
</script>