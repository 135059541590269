<template>
  <div class="auth-register-wrapper">
    <div class="auth-register-header d-lg-none">
      <div class="container">
        <div class="d-flex justify-content-center align-items-center justify-content-lg-start relative">
          <button type="button" class="header-arrow d-lg-none"  @click="handleIndex">
            <chevronLeft/>
          </button>
          <div class="auth-register-header-logo">
            <router-link to="/">
              <LogoBoxSVG/>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="auth-form-heading">
        <p class="auth-form-title">CRIAR CONTA</p>
        <p class="auth-form-subtitle">Cria o teu perfil e obtém acesso a conteudo de treino exclusivo </p>
      </div>
      <div class="auth-form-wrapper">
        <FormulateForm name="register" class="auth-form register-form" v-model="values" @submit="registerHandler">
          <div class="row box-form-row">
            <div class="col-12 col-lg-6 box-form-column">
              <div class="form-field">
                <FormulateInput type="text" name="first-name" validation="required" v-model="firstName" placeholder="Insira o seu primeiro nome" label="Primeiro Nome" />
              </div>
            </div>
            <div class="col-12 col-lg-6 box-form-column">
              <div class="form-field">
                <FormulateInput type="text" name="last-name" validation="required" v-model="lastName" placeholder="Insira o seu último nome" label="Último Nome" />
              </div>
            </div>
            <div class="col-12 col-lg-6 box-form-column">
              <div class="form-field">
                <FormulateInput type="select" name="gender" validation="required" v-model="gender" placeholder="Selecione o seu género" label="Género" :options="{m: 'Masculino', f: 'Feminino'}" />
              </div>
            </div>
            <div class="col-12 col-lg-6 box-form-column">
              <div class="form-field">
                <FormulateInput type="select" name="country" validation="required" v-model="country" placeholder="Selecione o seu país" label="País" :options="this.$root.countries" />
              </div>
            </div>
            <div class="col-12 box-form-column">
              <div class="form-field">
                <FormulateInput type="email" name="email" validation="required" v-model="email" placeholder="Insira o seu e-mail" label="E-mail" />
              </div>
            </div>
            <div class="col-12 col-lg-6 box-form-column">
              <div class="form-field">
                <FormulateInput type="password" name="password" validation="required" v-model="password" placeholder="Insira a sua palavra-passe" label="Palavra-passe" />
              </div>
            </div>
            <div class="col-12 col-lg-6 box-form-column">
              <div class="form-field">
                <FormulateInput type="password" name="repeat-password" validation="required" v-model="repeatPassword" placeholder="Confirme a sua palavra-passe" label="Confirmação Palavra-passe" />
              </div>
            </div>
            <div class="col-12 box-form-column">
              <div class="form-field checkbox">
                <FormulateInput type="checkbox" v-model="rgpdCheckbox" validation="required" label="Ao criar conta concorda com a Política de privacidade e os Termos de Utilização da BOXPT." :validation-messages="{required: 'Este campo é obrigatório'}" />
              </div>
            </div>
          </div>
          <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
            <p>{{ formResponseMessage }}</p>
          </div>
          <button type="submit" class="generic-btn red-gradient auth-form-submit">Criar conta</button>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo.vue";
import emailIcon from "@/components/svg/mail.vue";
import lockIcon from "@/components/svg/lock.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";

import {EventBus} from "@/event-bus";

export default {
    name: "auth-register",
    data() {
      return {
        values: {},
        errors: [],
        submitted: false,
        firstName: '',
        lastName: '',
        gender: '',
        country: '',
        email: '',
        password: '',
        repeatPassword: '',
        rgpdCheckbox: '',
        showMessage: false,
        formResponseMessage: '',
      };
    },
    components : {
      LogoBoxSVG,
      emailIcon,
      lockIcon,
      chevronLeft
    },
    methods: {
      handleIndex: function() {
        EventBus.$emit("bus-handleIndex");
      },
      registerHandler() {
        let validatedPassword = '';
        if ( this.password === this.repeatPassword ) {
          validatedPassword = this.password;

          this.showMessage = false;
          this.formResponseMessage = '';

          this.$root.registerFormInfo = {
            firstName: this.firstName,
            lastName: this.lastName,
            gender: this.gender,
            country: this.country,
            email: this.email,
            password: validatedPassword,
          };

          this.$router.push({ name: 'register' });
        } else {
          this.showMessage = true;
          this.formResponseMessage = 'The passwords do not match.';
        }
      },
    }
}
</script>