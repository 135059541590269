<template>
  <div class="relative" :class="[showLogin || showRegister || showResetPass ? ['auth-page'] : 'auth-index-page']">
    <div class="auth-image" v-if="areaItem && !!areaItem.media">
      <img :src="areaItem.media.general[0].background" v-if="!!areaItem.media.general" alt="">
      <div class="auth-bg-video" v-if="videoID">
        <iframe :src="'https://player.vimeo.com/video/'+ videoID +'?background=1&autoplay=1&muted=1&loop=1&byline=0&title=0'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>
      <div class="auth-image-bg d-none d-lg-block" v-if="showLogin || showRegister || showResetPass">
        <img src="@/assets/img/boxpt_background.svg" alt="">
      </div>
    </div>
    <div class="auth-content-wrapper">
      <div class="container">
        <div class="auth-content d-flex flex-column justify-content-between align-items-center" :class="[showLogin || showRegister || showResetPass ? ['flex-lg-row justify-content-lg-between align-items-lg-start'] : '']">
          <div class="header-logo">
            <button type="button" @click="handleIndex">
              <LogoBoxSVG/>
            </button>
          </div>

          <!-- LOGIN -->
          <AuthLogin v-if="showLogin" v-bind="{showLogin:true, showRegister:false, showResetPass:false, questionText: this.questionText}"/>

          <!-- REGISTER -->
          <AuthRegister v-if="showRegister"/>

          <!-- RESET PASSWORD -->
          <AuthRecoverPW v-if="showResetPass"/>

          <div class="auth-btn-wrapper">
            <div :class="[showLogin || showRegister || showResetPass ? ['d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-end'] : '']">
              <p class="auth-link" v-if="showLogin || showRegister || showResetPass">{{ questionText }}</p>
              <button type="button" class="generic-btn" :class="[showLogin || showRegister || showResetPass ? ['white'] : 'red-gradient']" @click="handleLogin" v-if="!showLogin && !showResetPass">Iniciar Sessão</button>
              <button type="button" class="generic-btn" :class="[showLogin || showRegister || showResetPass ? ['white auth-btn'] : 'grey']" @click="handleRegister" v-if="!showRegister">Criar Conta</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo";
import AuthLogin from "@/components/auth/auth-login";
import AuthRegister from "@/components/auth/auth-register";
import AuthRecoverPW from "@/components/auth/auth-recover-password";

import {EventBus} from "@/event-bus";
import axios from "axios";

export default {
  data() {
    return {
      lang: this.$root.siteLang,
      areaSEO: [],
      areaItem: [],
      errors: [],
      titleSEO: "",
      descriptionSEO: "",
      keywordsSEO: "",
      showLogin: false,
      showRegister: false,
      showResetPass: false,
      questionText: "",
      videoID: "",
    }
  },
  metaInfo() {
    return {
      title: this.titleSEO,
      htmlAttrs: {
        lang: this.$root.siteLang,
      },
      meta: [
        {vmid: 'description', name: 'description', content: this.descriptionSEO},
        {vmid: 'keywords', name: 'keywords', content: this.keywordsSEO},
      ]
    }
  },
  components: {
    LogoBoxSVG,
    AuthLogin,
    AuthRegister,
    AuthRecoverPW,
  },
  methods: {
    handleIndex: function() {
      this.showLogin = false;
      this.showRegister = false;
      this.showResetPass = false;
    },
    handleLogin: function() {
      this.showLogin = true;
      this.showRegister = false;
      this.showResetPass = false;

      this.questionText = "Ainda não tens conta?";
    },
    handleRegister: function() {
      this.showLogin = false;
      this.showRegister = true;
      this.showResetPass = false;

      this.questionText = "Já a está treinar com a  BOXPT?";
    },
  },
  mounted() {
    EventBus.$on('bus-handleResetPassword', () => {
      this.showLogin = false;
      this.showRegister = false;
      this.showResetPass = true;

      this.questionText = "Ainda não tens conta?";
    });

    EventBus.$on('bus-handleLogin', () => {
      this.showLogin = true;
      this.showRegister = false;
      this.showResetPass = false;

      this.questionText = "Ainda não tens conta?";
    });

    EventBus.$on('bus-handleIndex', () => {
      this.showLogin = false;
      this.showRegister = false;
      this.showResetPass = false;
    });
  },
  created() {
    //Background
    axios
        .get(process.env.VUE_APP_API_URL + 'areas/cp_28?with_category_title=0&with_seo=0&lang=' + this.lang)
        .then((response) => {
          this.areaItem = response.data;
          this.videoID = this.areaItem.media.videos[0].videoId;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
};
</script>