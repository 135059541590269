<template>
  <div class="auth-login-wrapper">
    <div class="auth-form-heading">
      <p class="auth-form-title d-none d-lg-block">BEM VINDO DE VOLTA!</p>
    </div>
    <div class="auth-form-wrapper">
      <FormulateForm name="login-form" class="auth-form login-form" v-model="values" @submit="loginHandler">
        <div class="form-field">
          <FormulateInput type="email" name="email" v-model="email" validation="required" placeholder="Insira o seu e-mail" />
          <div class="form-field-icon">
            <emailIcon/>
          </div>
        </div>
        <div class="form-field">
          <FormulateInput type="password" name="password" v-model="password" validation="required" placeholder="Insira a sua password" />
          <div class="form-field-icon">
            <lockIcon/>
          </div>
        </div>
        <div class="form-field">
          <FormulateInput type="checkbox" label="Manter sessão ativa" v-model="keepLoggedIn" />
        </div>
        <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
          <p>{{ formResponseMessage }}</p>
        </div>
        <button type="submit" class="generic-btn red-gradient auth-form-submit">Iniciar Sessão</button>
      </FormulateForm>
    </div>
    <button type="button" class="reset-password-link" @click="handleResetPassword">Esqueceu-se da palavra-passe?</button>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo.vue";
import emailIcon from "@/components/svg/mail.vue";
import lockIcon from "@/components/svg/lock.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";

import {EventBus} from "@/event-bus";
import axios from "axios";
import qs from "qs";

export default {
    name: "auth-login",
    data() {
      return {
        values: {},
        errors: [],
        submitted: false,
        email: '',
        password: '',
        keepLoggedIn: '',
        showMessage: false,
        formResponseMessage: '',
      };
    },
    props: {
      showLogin: {
        required: true,
      },
      showRegister: {
        required: true,
      },
      showResetPass: {
        required: true,
      },
      questionText: {
        required: true,
      },
    },
    components : {
      LogoBoxSVG,
      emailIcon,
      lockIcon,
      chevronLeft
    },
    methods: {
      handleResetPassword: function() {
        EventBus.$emit("bus-handleResetPassword");
      },
      resetLoginHandler() {
        this.$formulate.reset('login-form');
        this.email = '';
        this.password = '';
        this.showMessage = false;
        this.formResponseMessage = '';
      },
      loginHandler() {
        const requestBody = {
          email: this.email,
          password: this.password,
        };

        axios
            .post(process.env.VUE_APP_API_URL + 'customer/login', qs.stringify(requestBody))
            .then((response) => {
              this.$root.loggedIn = true;
              this.$root.authKey = response.data.model.auth_key;

              /*if (this.keepLoggedIn === true) {
                this.$cookies.set("loggedIn",true);
                this.$cookies.set("authKey",response.data.model.auth_key);
              } else {*/
                this.$cookies.set("loggedIn",true,60 * 60 * 4);
                this.$cookies.set("authKey",response.data.model.auth_key,60 * 60 * 4);
              /*}*/

              this.resetLoginHandler();
              this.$root.userInfo = response.data.model;

              if ( response.data.model.photo != null ) {
                this.$root.userInfo.photo = response.data.model.baseURL + response.data.model.photo;
              }

              this.$router.push({ path: '/dashboard' })
            })
            .catch((e) => {
              this.errors.push(e);
              this.showMessage = true;
              this.formResponseMessage = e.response.data.errors.password[0];
            });
      },
    }
}
</script>